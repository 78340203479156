const MONTHS_FULL = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

const MONTHS_ABBREV = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec'
];

export const formatFullDate = (isoString, abbrev = false) => {
    const date = new Date(isoString);
    const month = abbrev ? MONTHS_ABBREV[date.getUTCMonth()] : MONTHS_FULL[date.getUTCMonth()];
    return `${month} ${date.getUTCDate()}, ${date.getUTCFullYear()}`;
};
