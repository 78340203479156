import React from 'react';
import { graphql } from 'gatsby';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../../components/layout';
import Seo from '../../components/seo';
import BlogCard from '../../components/blog-card';

const useStyles = makeStyles(theme => ({
    title: {
        paddingTop: 40,
        paddingBottom: 50,
        textAlign: 'center',
    },
    cardWrapper: {
      padding: 30
    }
}));

const EXTERNAL_BLOG_POSTS = [
  {
    frontmatter: {
      title: 'Tips on How Staff Engineers Can Impact Incidents',
      date: '2023-12-09T00:00:00.000Z',
      description: 'This is an article based off of a talk I gave at QCon San Francisco 2023'
    },
    fields: {
      slug: 'https://www.infoq.com/articles/staff-engineers-impact-incidents'
    },
    excerpt: 'As a staff engineer, I recently led my team through one of the worst incidents of my career. In my talk at QCon SF 2023, I told the story of this situation. An infrastructure change introduced automation that ended up erroneously deleting critical customer data. It took us three days to fully resolve the outage and restore the data.  In retrospect, there were many things we could have done differently - from preventing the initial incident to improving our response process. This showed me that staff-plus engineers have an opportunity in all stages of the incident process to drive positive change.'
  },
  {
    frontmatter: {
      title: 'Tips for starting as a Staff+ Software Engineer at a new company',
      date: '2022-11-30T00:00:00.000Z',
      description: 'This is an article based off of a talk I gave at REFACTR.TECH 2022'
    },
    fields: {
      slug: 'https://www.lob.com/blog/staff-engineer-at-a-new-company'
    },
    excerpt: 'My experience getting hired at Lob at the Staff level made me realize I may have some learnings to share. I\'ve been at the Staff level previously, but that was at a company where I had been promoted up to those levels; I was already very familiar with my team, my company, our business, and our platform, so it was just a natural progression of responsibility for me. But getting my current job was the first time I\'d started at a new company at such a level of seniority. And while at least for me, every new job is an invitation for an Imposter Syndrome attack, this had the potential to be an even greater one. But I fought it, and I was really intentional about how I approached getting up to speed be effective more quickly and smoothly than I have in previous new job experiences.'
  }
];

const BlogIndex = ({ data, location }) => {
    const classes = useStyles();

    const posts = [ ...EXTERNAL_BLOG_POSTS.map((post) => ({ node: post, isExternal: true })), ...data.allMarkdownRemark.edges ];
    // sort by the blog post date DESC
    posts.sort((a, b) => {
      const date1 = a.node.frontmatter.date;
      const date2 = b.node.frontmatter.date;

      if (date1 < date2) return 1;

      if (date1 > date2) return -1;

      return 0;
    });

    return (
        <Layout location={location} title="Blog Posts">
            <Seo title="All posts" />
            <Container maxWidth="md">
                <Typography variant="h1" className={classes.title}>Blog Posts</Typography>

                  {posts.map(({ node, isExternal }) => {
                    const title = node.frontmatter.title || node.fields.slug
                    return (
                      <div
                        className={classes.cardWrapper}
                        key={node.fields.slug}
                      >
                        <BlogCard
                          to={node.fields.slug}
                          title={title}
                          date={node.frontmatter.date}
                          description={node.frontmatter.description}
                          excerpt={node.excerpt}
                          isExternal={isExternal}
                        />
                      </div>
                    )
                  })}
            </Container>
        </Layout>
    );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date
            title
            description
          }
        }
      }
    }
  }
`
