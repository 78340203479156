import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby-theme-material-ui';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { formatFullDate } from '../utils/dates';

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: 20,
        textAlign: 'left',
    },
    cardAction: {
        padding: 30,

        [theme.breakpoints.down('md')]: {
            padding: 15
        },
    },
    title: {
        paddingTop: 10,
        paddingBottom: 15,
        textAlign: 'left',

        [theme.breakpoints.down('sm')]: {
            paddingTop: 0,
            paddingBottom: 5
        },
    }
}));

const BlogCard = ({
    to,
    title,
    date,
    description,
    excerpt,
    isExternal
}) => {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardActionArea
                className={classes.cardAction}
                component={Link}
                href={to}
                target={isExternal ? '_blank' : null}
                rel={isExternal ? 'noreferrer noopener' : null}
            >
                <CardContent>
                    <Typography
                        variant="subtitle1"
                        component="p"
                        color="textSecondary"
                        gutterBottom
                    >
                        {formatFullDate(date)}
                    </Typography>
                    <Typography
                        className={classes.title}
                        variant="h2"
                        color="primary"
                    >
                        {title}
                    </Typography>
                    <Typography
                        variant="body1"
                        dangerouslySetInnerHTML={{
                            __html: description || excerpt,
                        }}
                    />
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

BlogCard.defaultProps = {
    description: null,
    excerpt: null,
    isExternal: false
};

BlogCard.propTypes = {
    to: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    description: PropTypes.string,
    excerpt: PropTypes.node,
    isExternal: PropTypes.bool
};

export default BlogCard;
